import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100px;
  background-color: white;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 11111;
  display: flex;
  align-items: center;
  padding: 0 50px;
  border-bottom: ${({ isScrolled }) => isScrolled ? '1px solid #E7E7EB' : 'none'};
  @media (max-width: 1024px) {
    padding: 0 20px;
  }
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 1.65rem;
  color: #16163F;
`;

export const MenuContainer = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  gap: 3rem;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const MenuOption = styled.a`
  font-size: 1rem;
  cursor: pointer;
  color: ${({ isSelected }) => isSelected ? '#9E3FFD' : '#16163F'};
  text-decoration: none;
  &:hover {
    color: #9E3FFD;
  }
`;

export const BurgerButton = styled.img`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  display: none;
  margin-left: auto;
  width: 22px;
  @media (max-width: 1024px) {
    display: block;
  }
`;

export const MobileMenuContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  visibility: ${({ isOpen }) => isOpen ? 'visible' : 'hidden'};
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
`;

export const MobileMenu = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 70%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out; /* Плавный переход */
  transform: translateX(100%); /* По умолчанию меню скрыто за экраном */

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateX(0); /* Выезд меню при открытии */
    `}
`;

export const Close = styled.img`
  width: 18px;
  height: 18px;
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;