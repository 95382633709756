import React from 'react';
import { Parallax } from "react-parallax";

import home from '../../../../assets/images/home-bg-1.png';
import * as S from './styled';

export const HomeSection = () => {
  const handlePress = () => {
    const element = document.getElementById('Services');
    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      top: elementPosition - 100,
      behavior: "smooth",
    })
  }
  return (
    <S.Container id="Home">
    <Parallax style={{ width: '100%', height: '100%'}} bgImage={home}  strength={400}>
      <S.InnerContainer>
        <S.Title>MYJOYGAMES — Boosting Minds Through Innovative Mobile Solutions</S.Title>
        <S.Description>Discover innovative mobile solutions created by MYJOYGAMES to educate, entertain, and inspire. Experience our flagship app, Brainary, and redefine the way you learn and grow.</S.Description>
        <S.Button onClick={handlePress}>Learn more</S.Button>
      </S.InnerContainer>
    </Parallax>
    </S.Container>
  )
}