import React from 'react';
import { Parallax } from "react-parallax";

import about from '../../../../assets/images/about-bg.png';
import * as S from './styled';

export const AboutSection = () => {
  // const imageUrl = "https://static.wixstatic.com/media/c837a6_38f0ee339302476e9f04ad2cab58a10e~mv2.png/v1/fill/w_490,h_916,al_bl,q_90,usm_0.66_1.00_0.01,enc_avif,quality_auto/c837a6_38f0ee339302476e9f04ad2cab58a10e~mv2.png";
  const handlePress = () => {
    window.location.replace("https://myjoygames.com/onboarding");
  }
  return (
    <S.Container id="About">
      <S.LeftPart>
        <S.Title>
          about us
        </S.Title>
        <S.Subtitle>Discover Brainary: A MYJOYGAMES Innovation</S.Subtitle>
        <S.Text>Brainary, developed by MYJOYGAMES, offers a seamless blend of learning and entertainment. Designed to transform your downtime into productive moments, Brainary provides:</S.Text>
        <S.Text>
          <ul>
            <li>Opportunities to train your brain and improve critical skills.</li>
            <li>A combination of intellectual development and engaging gameplay.</li>
          </ul>
        </S.Text>
      <S.Text>With over 100,000 users, Brainary transforms everyday moments into productive and rewarding experiences.</S.Text>
      <S.Button onClick={handlePress}>Explore Brainary</S.Button>
      <S.MobileButton onClick={handlePress}>Discover Brainary</S.MobileButton>
      </S.LeftPart>
      <S.RightPart>
      <Parallax style={{ width: '100%', height: '100%'}} bgImage={about} bgImageStyle={{ width: '100%'}} strength={500}>

      </Parallax>
      {/* <img src={about} style={{ width: '100%', height: '100%', ob: 'contain'}} /> */}
      </S.RightPart>
    </S.Container>
  )
}