import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
  @media (max-width: 1024px) {
    height: auto;
    padding: 50px;
  }
`;

export const Title = styled.div`
  color: #9E3FFD;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
`;

export const Subtitle = styled.div`
  color: #16163F;
  font-size: 1.79rem;
  font-weight: 700;
  text-align: center;
`;

export const ItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 350px;
  text-align: center;
  align-items: center;
  gap: 20px;
  & img {
    width: 150px;
    height: 150px;
  }
`;

export const ItemTitle = styled.div`
  font-size: 0.83rem;
  font-weight: 700;
  color: #16163F;
`;

export const ItemDescription = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  color: #16163F;
`;