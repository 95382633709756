import styled from 'styled-components';
import contactBg from '../../../../assets/images/contact-bg.png';
export const Container = styled.section`
  /* height: 100vh; */
  display: flex;
  background-image: url(${contactBg});
  background-size: contain;
  padding: 100px 50px;
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 30px;
  }
`;

export const MainPart = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

export const LeftPart = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const RightPart = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const Title = styled.div`
  color: #9E3FFD;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const Subtitle = styled.div`
  color: #16163F;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 40px;
`;

export const Description = styled.div`
  color: #16163F;
  font-size: 1rem;
  font-weight: 400;
  max-width: 40%;
  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

export const Button = styled.div`
  background-color: #9E3FFD;
  cursor: pointer;
  width: 204px;
  /* height: 50px; */
  padding: 20px 0px;
  border-radius: 100px;
  font-size: 1rem;
  font-weight: 300;
  color: white;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;