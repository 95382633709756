import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
`;

export const LeftPart = styled.div`
  flex: 1;
  padding-left: 50px;
  padding-top: 50px;
  @media (max-width: 1024px) {
    padding: 50px 20px;
  }
`;

export const RightPart = styled.div`
  flex: 1;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Title = styled.div`
  color: #9E3FFD;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
`;

export const Subtitle = styled.div`
  font-size: 2rem;
  font-weight: 700;
  color: #16163F;
`;

export const Text = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: #16163F;
`;

export const Button = styled.div`
  cursor: pointer;
  background-color: #9E3FFD;
  width: 224px;
  /* height: 50px; */
  padding: 20px 0px;
  border-radius: 100px;
  font-size: 1rem;
  font-weight: 300;
  color: white;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    /* width: 100%; */
    display: none;
  }
`;

export const MobileButton = styled(Button)`
  display: none;
  @media (max-width: 1024px) {
    display: flex;
    width: 100%;
  }
`;