import React from 'react';
import styled from 'styled-components';
import { Header } from '../../components/Header';
import { HomeSection } from './sections/Home';
import { AboutSection } from './sections/About';
import { ServicesSection } from './sections/Services';
import { ExpertiseSection } from './sections/Expertise';
import { TestimonialsSection } from './sections/Testimonials';
import { ContactSection } from './sections/Contact';
import { Footer } from '../../components/Footer';


export const MainPage = () => {
  return (
    <Container>
      <Header />
      <HomeSection />
      <ServicesSection />
      <ExpertiseSection />
      <AboutSection />
      <TestimonialsSection />
      <ContactSection />
      <Footer />
    </Container>
  )
}

const Container = styled.div``;