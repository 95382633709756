import React from 'react';
import styled from 'styled-components';

export const Footer = () => {
  return (
    <Container>
      <Text>© MYJOYGAMES 2024</Text>
    </Container>
  )
}

const Container = styled.footer`
  height: 90px;
  background-color: #16163f;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.div`
  font-size: 1rem;
  color: white;
  font-weight: 400;
`;