import React from 'react';
import * as S from './styled';
// import { Input } from '../../../../components/Input';

export const ContactSection = () => {
  function openMailClient(email, subject, body) {
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
}

const handlePress = () => {
  openMailClient('support@blast-interactive.com', '', '');
}
  return (
    <S.Container id="Contact">
      <S.MainPart>
        <S.Title>GET IN TOUCH</S.Title>
        <S.Subtitle>Let’s Unlock Your Potential</S.Subtitle>
        <S.Description>Connect with us to explore how Brainary can enhance your cognitive abilities and boost your intellectual growth. Reach out to our team to discuss how our mobile solutions can support your personal development and learning goals.</S.Description>
        <S.Button onClick={handlePress}>
          Write to us
        </S.Button>
      </S.MainPart>
    </S.Container>
  )
}