import styled from 'styled-components';

export const Container = styled.section`
  height: 100vh;
  background-color: #9E3FFD;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    height: auto;
    padding: 40px 10px;
  }
`;

export const Title = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-bottom: 20px;
`;

export const TestimonialText = styled.div`
  font-size: 1.83rem;
  font-weight: 700;
  color: white;
  text-align: center;
  margin-bottom: 20px;
  max-width: 70%;
  @media (max-width: 1024px) {
    max-width: 100%;
    font-size: 1.1rem;
  }
`;

export const TestimonialAuthor = styled.div`
  font-size: 1rem;
  font-weight: 700;
  color: white;
  text-align: center;
    @media (max-width: 1024px) {
    font-size: 0.9rem;
  }
`;

export const DotsContainer = styled.div`
  display: flex;
  margin-top: 40px;
  gap: 20px;
`;

export const Dot = styled.div`
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: ${({ isSelected }) => isSelected ? 'white' : '#B272EE'};
  @media (max-width: 1024px) {
    width: 12px;
    height: 12px;
  }
  
`;