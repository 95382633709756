import React from 'react';
import styled from 'styled-components';

export const ExpertiseSection = () => {
  const items = [
    {
      title: '150+',
      description: 'Games and puzzles to boost cognitive skills.',
    },
    {
      title: '500+',
      description: 'Hours of brain training and exercises.'
    },
    {
      title: '200+',
      description: `Quizzes to test memory, logic, and problem-solving.`,
    }
  ]
  return (
    <Container>
      <Title>Expertise in Numbers</Title>
      <ItemsContainer>
        {
          items.map((item, index) => (
            <>
            <div style={{ maxWidth: 200 }}>
              <ItemTitle>{item.title}</ItemTitle>
              <ItemDescription>{item.description}</ItemDescription>
            </div>
            {index !== items.length - 1 ? <div style={{ width: 10, height: 10, backgroundColor: 'white', borderRadius: 10 }} /> : null}
            </>
          ))
        }
      </ItemsContainer>
    </Container>
  )
}

const Container = styled.div`
  height: 473px;
  background-color: #16163f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    height: auto;
    padding: 50px 0px;
  }
`;

const Title = styled.div`
  font-size: 1.8rem;
  font-weight: 700;
  color: white;
  text-align: center;
  margin-bottom: 40px;
`;

const ItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const ItemTitle = styled.div`
  font-size: 3.33rem;
  font-weight: 700;
  color: white;
  text-align: center;
`;

const ItemDescription = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  color: white;
  text-align: center;
`;