import React, { useEffect, useState } from 'react';
import * as S from './styled';

import burger from '../../assets/images/burger-2.png';
import close from '../../assets/images/close.png';


const sections = [
  'Home',
  'Services',
  'About',
  'Testimonials',
  'Contact',
]

export const Header = () => {
  const [activeSection, setActiveSection] = useState(sections[0]);
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      setIsScrolled(Boolean(window.scrollY));
    })
  }, [])
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.6 }
    );

    sections.forEach((sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      setMobileMenuVisible(false)
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: elementPosition - 100,
        behavior: "smooth",
      })
    }
  };

  return (
    <S.Container isScrolled={isScrolled}>
      <S.Title>Brainary</S.Title>
      <S.BurgerButton onClick={() => setMobileMenuVisible(true)} src={burger} />
      <S.MobileMenuContainer isOpen={isMobileMenuVisible}>
        <S.MobileMenu isOpen={isMobileMenuVisible}>
          <S.Close onClick={() => setMobileMenuVisible(false)} src={close} />
          {
            sections.map(option => <S.MenuOption style={{ marginBottom: 20, fontSize: '1.2rem' }} onClick={() => handleScroll(option)} isSelected={option === activeSection}>{option}</S.MenuOption>)
          }
        </S.MobileMenu>
      </S.MobileMenuContainer>
      <S.MenuContainer>
        {
          sections.map(option => <S.MenuOption onClick={() => handleScroll(option)} isSelected={option === activeSection}>{option}</S.MenuOption>)
        }
      </S.MenuContainer>
    </S.Container>
  )
}