import React from 'react';
import * as S from './styled';

import service2 from '../../../../assets/icons/service-1.svg';
import service1 from '../../../../assets/icons/service-2.svg';
import service3 from '../../../../assets/icons/service-3.svg';

export const ServicesSection = () => {
  const items = [
    {
      img: service1,
      title: 'Brain Training Games',
      description: 'Engage in a variety of stimulating games and puzzles designed to challenge your mind, improve concentration, and enhance cognitive abilities. With Brainary, training your brain becomes both fun and effective.'
    },
    {
      img: service2,
      title: 'Personalized Learning Paths',
      description: 'Our app adapts to your progress, offering personalized challenges and tasks tailored to your individual growth. With a unique learning path, you can unlock new intellectual potentials at your own pace.'
    },
    {
      img: service3,
      title: 'Progress Tracking & Insights',
      description: 'Track your achievements with detailed insights into your cognitive development. Monitor your improvement and push your limits with real-time feedback on your brain training journey.'
    },
  ]
  return (
    <S.Container id="Services">
      <S.Title>our services</S.Title>
      <S.Subtitle>Empowering Minds with <br /> Innovative Mobile Solutions</S.Subtitle>
      <S.ItemsContainer>
        {
          items.map(item => (
            <S.Item>
              <img src={item.img} />
              <S.ItemTitle>{item.title}</S.ItemTitle>
              <S.ItemDescription>{item.description}</S.ItemDescription>
            </S.Item>
          ))
        }

      </S.ItemsContainer>
    </S.Container>
  )
}