import styled from 'styled-components';

export const Container = styled.section`
  min-height: 100vh;
  @media (max-width: 1024px) {
    height: 100vh;
  }
`;

export const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 50px;
  padding-bottom: 40px;
  @media (max-width: 1024px) {
    padding-left: 20px;
  }
`;

export const Title = styled.h1`
  font-size: 3.33rem;
  font-weight: 800;
  color: #16163F;
  max-width: 60%;
  
  @media (max-width: 1024px) {
    font-size: 2rem;
    max-width: 100%;
  }
`;

export const Description = styled.div`
  color: #16163F;
  font-size: 1.17rem;
  font-weight: 300;
  max-width: 35%;
  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

export const Button = styled.div`
  cursor: pointer;
  background-color: #9E3FFD;
  width: 174px;
  /* height: 50px; */
  padding: 20px 0px;
  border-radius: 100px;
  font-size: 1rem;
  font-weight: 300;
  color: white;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;