import React, { useState } from 'react';

import * as S from './styled';

const testimonials = [
  {
    text: "“Since I started using Brainary, I’ve noticed a real difference in my focus and problem-solving skills. The challenges are fun but also effective in improving my memory and cognitive abilities. I feel sharper at work and more engaged in daily tasks. I would definitely recommend it to anyone looking to train their brain and improve their mental agility.”",
    author: "John Davis",
    position: "Productivity Coach",
  },
  {
    text: "“Brainary has significantly improved my cognitive abilities. As someone who is always looking for ways to enhance mental sharpness and focus, this app has been a game-changer. The variety of puzzles and quizzes keeps me engaged, while tracking my progress helps me see real improvement. I feel more focused and productive in both my personal and professional life. I highly recommend Brainary to anyone looking to boost their brainpower.”",
    author: "Emily Roberts",
    position: "Business Analyst",
  },
  {
    text: "“Brainary is an incredible app for anyone serious about mental development. The interactive quizzes and brain-boosting tasks have helped me improve my concentration and sharpen my decision-making abilities. I love the fact that I can track my progress and see tangible results over time. This app has truly helped me grow both personally and professionally.”",
    author: "Michael Lee",
    position: "Software Developer",
  },
]
export const TestimonialsSection = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const selected = testimonials[selectedIndex];
  return (
    <S.Container id="Testimonials">
      <S.Title>CLIENT TESTIMONIALS</S.Title>
      <S.TestimonialText>
        {selected.text}
      </S.TestimonialText>
      <S.TestimonialAuthor>{selected.author}</S.TestimonialAuthor>
      <S.TestimonialAuthor>{selected.position}</S.TestimonialAuthor>
      <S.DotsContainer>
        {testimonials.map((_, index) => <S.Dot isSelected={index === selectedIndex} onClick={() => setSelectedIndex(index)} />)}
      </S.DotsContainer>
    </S.Container>
  )
}